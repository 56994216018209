import React from 'react';

import { useRenderContext } from '../../pages/home/context';

const WrapperSectionText = (Base) => {
  const Wrapper = (props) => {
    const { isEshops } = useRenderContext();

    return (<Base
      {...props}
      isEshops={isEshops}
    />);
  };

  return Wrapper;
};

export default WrapperSectionText;
